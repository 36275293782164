import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
} from '@mui/material';

import { app } from '~common/app.model';
import Button from '~inputs/Button';
import IconButton from '~inputs/IconButton';
import { useActions } from '~common/utils/hooks.utils';

type ValueType = string | number;

type DividerOption = { type: 'divider' };
type HeaderOption = { type: 'header' };
type ComponentOption = { component: JSX.Element };
/** Option for a basic action */
export type BasicOption<T = string> = {
  value: T;
  title: string;
  onSelect?: (value?: T, option?: BasicOption<T>) => void;
  icon?: JSX.Element;
  hidden?: boolean;
  disabled?: boolean;
};
/** Option for opening a modal */
export type ModalOption = {
  value: string;
  title: string;
  icon: JSX.Element;
  modalType: string;
  modalProps?: Record<string, unknown>;
  hidden?: boolean;
  disabled?: boolean;
};
/** Option for opening another menu */
export type MenuOption<T = string> = {
  value: string;
  title: string;
  options: Option<T>[];
  onSelect?: (value?: T, option?: Option<T>) => void;
  icon?: JSX.Element;
  hidden?: boolean;
  disabled?: boolean;
};

/** Options get mapped to a list item in the menu */
export type Option<T = string> = {
  /** Used as a key for each option */
  value: T;
  hidden?: boolean;
  tooltip?: string;
  selected?: boolean;
} & (
  | DividerOption
  | HeaderOption
  | ComponentOption
  | BasicOption<T>
  | ModalOption
  | MenuOption<T>
);

// TODO: isIconButton: boolean
export interface Props<T>
  extends Omit<React.ComponentProps<typeof Button>, 'onSelect'> {
  options: Option<T>[];
  onSelect?: (value?: T, option?: Option<T>) => void;
  onClick?: (event: any) => void;
  onOpen?: () => void;
  onExit?: () => void;
  icon?: React.FunctionComponent<any> | React.ComponentClass<any> | JSX.Element;
  size?: 'small' | 'medium' | 'large';
  xsmall?: boolean;
  hideEmptyMenu?: boolean;
  listItem?: boolean;
  keepMenuClosed?: boolean;
}

const MenuButton = <T extends ValueType>(
  {
    onClick,
    onSelect,
    onOpen,
    onExit,
    hideEmptyMenu = true,
    listItem,
    options,
    keepMenuClosed = false,
    ...props
  }: Props<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const { setOpenModal } = useActions(app.actions);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClick) onClick(event);
    if (keepMenuClosed) return;
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setOpenMenu(false);
  };

  function select(event: React.MouseEvent, value?: T, option?: Option<T>) {
    handleRequestClose(event);
    onSelect && onSelect(value, option);
    if (!option) return;
    if ('onSelect' in option) {
      option.onSelect?.(value, option);
    }
    if ('modalType' in option) {
      setOpenModal(option.modalType, option.modalProps);
    }
  }

  const filteredOptions = options
    .filter(o => !o.hidden)
    .filter((o, index) => index > 0 || !('type' in o) || o.type !== 'divider');

  if (filteredOptions.length === 0 && hideEmptyMenu) return null;

  return (
    <>
      {listItem ? (
        <MenuItem onClick={handleClick} component="button" ref={ref} {...props}>
          {props.startIcon && <ListItemIcon>{props.startIcon}</ListItemIcon>}
          <ListItemText primary={props.children} />
        </MenuItem>
      ) : props.icon ? (
        <IconButton
          {...props}
          size={props.size === 'large' ? 'medium' : props.size}
          onClick={handleClick}
          ref={ref}
        >
          {'props' in props.icon ? props.icon : React.createElement(props.icon)}
          {props.children}
        </IconButton>
      ) : (
        <Button
          {...props}
          onClick={handleClick}
          ref={ref}
          // Icons are half a pixel thinner than text, this ensures buttons
          // have uniform height
          sx={
            typeof props.children !== 'string'
              ? { '& > *': { marginBlockEnd: '0.5px' } }
              : undefined
          }
        >
          {props.children}
        </Button>
      )}
      {filteredOptions.length > 0 && (
        <StyledMenu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={openMenu}
          onClose={handleRequestClose}
          TransitionProps={{
            onEnter: onOpen,
            onExited: onExit,
          }}
          arial-label={props['aria-label']}
        >
          {filteredOptions &&
            filteredOptions.map((option, index) => {
              if (option.hidden) return null;
              if ('type' in option && option.type === 'divider')
                return <Divider key={index} />;
              else if ('component' in option)
                return React.cloneElement(option.component, { key: index });
              else if ('options' in option && option.options.length)
                return (
                  <ForwardedMenuButton
                    listItem
                    startIcon={option.icon}
                    {...option}
                  >
                    {option.title}
                  </ForwardedMenuButton>
                );
              else if ('type' in option && option.type === 'header')
                return (
                  <ListSubheader key={option.value}>
                    {option.value}
                  </ListSubheader>
                );
              else
                return (
                  <MenuItem
                    key={option.value}
                    disabled={option.disabled}
                    selected={option.selected}
                    onClick={event => select(event, option.value as T, option)}
                  >
                    {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                    {option.tooltip ? (
                      <Tooltip title={option.tooltip}>
                        <ListItemText> {option.title} </ListItemText>
                      </Tooltip>
                    ) : (
                      <ListItemText primary={option.title} />
                    )}
                  </MenuItem>
                );
            })}
        </StyledMenu>
      )}
    </>
  );
};

const StyledMenu = styled(Menu)`
  @media print {
    display: none !important;
  }
`;

const ForwardedMenuButton = React.forwardRef(MenuButton);

export default ForwardedMenuButton;
